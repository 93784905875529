import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import useSiteMetadata from "../hooks/use-site-metadata";
import logo from "../images/logo-white.svg";
import bg from "../images/background.jpg";
import ogImage from "../images/og-image.png";
import appleStoreWhiteButton from "../images/apple-store-white-button.svg";
import appleStoreBlackButton from "../images/apple-store-black-button.svg";
import googlePlayWhiteButton from "../images/google-play-white-button.svg";
import googlePlayBlackButton from "../images/google-play-black-button.svg";
import HeroMockup from "../components/hero-mockup";
import Icons from "../components/icons";
import Feature from "../components/feature";
import AnimateOnScroll from "../components/animate-on-scroll";
import footerMockup from "../images/iphone-mockup.png";
import footerMockup2x from "../images/iphone-mockup@2x.png";
import footerMockup3x from "../images/iphone-mockup@3x.png";
import ResponsiveImage from "../components/responsive-image";

const IndexPage = () => {
  const { title, description, siteUrl, appleStoreLink, googlePlayLink } =
    useSiteMetadata();
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:image" content={ogImage} />
        <link rel="canonical" href={siteUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:card" content="app" />
        <meta name="twitter:site" content="@mattpatterson94" />
        <meta name="twitter:app:country" content="AU" />
        <meta name="twitter:app:id:iphone" content="1556363293" />
        <meta name="twitter:app:id:ipad" content="1556363293" />
        <meta
          name="twitter:app:id:googleplay"
          content="com.oppyfinder.client"
        />
      </Helmet>
      <div
        className="xl:h-screen bg-black overflow-hidden relative bg-cover"
        style={{ backgroundImage: `url(${bg})` }}
      >
        {/* Start Hero */}
        <div className="xl:h-full lg:container mx-auto px-5">
          <div className="absolute top-8">
            <a href={siteUrl}>
              <img src={logo} width={200} alt="Oppyfinder Logo" />
            </a>
          </div>
          <div className="xl:h-full grid gap-y-8 text-white sm:gap-y-0 sm:gap-x-5 lg:gap-x-10 sm:grid-cols-3 md:grid-cols-2 mt-32 mb-8 xl:mt-auto content-center">
            <div className="sm:col-span-2 md:col-span-1 place-self-center space-y-5 md:space-y-8">
              <h1 className="text-4xl text-shadow sm:text-5xl xl:text-6xl font-light">
                Find Secondhand Stores{" "}
                <strong className={"font-semibold"}>Australia Wide</strong>
              </h1>
              <p className="max-w-sm text-shadow text-lg md:text-2xl">
                Oppyfinder features secondhand and vintage stores across
                Australia. Find stores near you and get directions.
              </p>
              <div className="flex space-x-4">
                <a href={appleStoreLink} target={"_blank"} rel="noreferrer">
                  <img
                    className="shadow rounded-lg transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
                    src={appleStoreWhiteButton}
                    width={150}
                    alt="Download on the Apple Store"
                  />
                </a>
                <a href={googlePlayLink} target={"_blank"} rel="noreferrer">
                  <img
                    className="shadow rounded-lg transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
                    src={googlePlayWhiteButton}
                    width={160}
                    alt="Download on the Google Play Store"
                  />
                </a>
              </div>
            </div>
            <div className="grid justify-items-center mx-auto hidden sm:block">
              <AnimateOnScroll transition={"slide-up"}>
                <HeroMockup />
              </AnimateOnScroll>
            </div>
          </div>
        </div>
        <div className="hidden sm:block sm:absolute bottom-6 sm:inset-x-1/2">
          <Icons.ArrowDown className={"animate-bounce"} />
        </div>
      </div>
      <div className="sm:hidden flex justify-center mx-auto bg-gray-100 py-10">
        <AnimateOnScroll transition={"fade-in"}>
          <HeroMockup />
        </AnimateOnScroll>
      </div>
      {/* End Hero */}
      <section className="container mx-auto px-5 py-12 lg:py-28">
        {/* Start About App */}
        <div className="grid gap-4 md:gap-10 lg:grid-cols-3">
          <div>
            <h2 className="text-2xl md:text-3xl font-semibold">
              About Our App
            </h2>
          </div>
          <div className="lg:col-span-2">
            <p className="text-lg md:text-2xl">
              Oppyfinder&apos;s mission is to help increase visibility to the
              vast variety of charitable stores across Australia, while
              providing a presence to the less known, independent secondhand
              stores that often go unnoticed. We also want to provide users with
              an intuitive and easy to use mobile application that makes finding
              secondhand stores nearby an easy process.
              <br />
              <br />
              Something missing? Help other users by submitting stores, photos
              and information updates through the app!
            </p>
          </div>
        </div>
      </section>
      {/* End About App */}
      <div className="container mx-auto sm:px-4">
        <hr className="bg-gray-400" />
      </div>
      <section className="container mx-auto px-5 py-12 lg:py-28">
        {/* Start Features */}
        <div className="grid xl:grid-cols-3 gap-10">
          <div>
            <h2 className="text-2xl md:text-3xl font-semibold">Features</h2>
          </div>
          <div className="xl:col-span-2 grid gap-y-8 sm:gap-10 sm:grid-cols-2">
            <Feature
              icon={<Icons.MapMarker />}
              title={"Find Stores Nearby"}
              description={
                "We can use your location to provide you with op shops that are close to our location."
              }
            />
            <Feature
              icon={<Icons.Truck />}
              title={"Get Directions"}
              description={
                "By tapping on the map when viewing an op shop you'll be in your maps application with directions to the op shop"
              }
            />
            <Feature
              icon={<Icons.Support />}
              title={"Help us fill in the blanks"}
              description={
                "Help us keep Oppyfinder up to date by submitting op shop information, reviews and photos. If we have missed a op shop, submit it and we'll add it"
              }
            />
            <Feature
              icon={<Icons.Heart />}
              title={"Keep track of your favourites"}
              description={
                "Have a list of favourite op shops? When viewing a op shop, press on the heart and it will appear in your favourites list"
              }
            />
            <Feature
              icon={<Icons.Bin />}
              title={"Charity Bins (Coming Soon)"}
              description={
                "Have a bunch of old items you wish to donate? Oppyfinder can provide you locations to charity bins near you!"
              }
            />
          </div>
        </div>
      </section>
      {/* End Features */}
      <section className="container mx-auto md:px-5">
        {/* Start Download App */}
        <div className="pt-12 lg:pt-24 px-5 md:px-0 text-center bg-teal space-y-8 md:space-y-12">
          <div className="space-y-4">
            <h2 className="text-2xl md:text-3xl font-semibold">Download now</h2>
            <p className="text-base">Start finding secondhand stores now!</p>
          </div>
          <div className="flex justify-center space-x-4">
            <a href={appleStoreLink} target={"_blank"} rel="noreferrer">
              <img
                className="shadow rounded-lg transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
                src={appleStoreBlackButton}
                width={150}
                alt="Download on the Apple Store"
              />
            </a>
            <a href={googlePlayLink} target={"_blank"} rel="noreferrer">
              <img
                className="shadow rounded-lg transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
                src={googlePlayBlackButton}
                width={160}
                alt="Download on the Google Play Store"
              />
            </a>
          </div>
          <div className="grid justify-items-center">
            <AnimateOnScroll
              className="w-11/12 sm:w-2/3 md:w-1/3"
              transition={"slide-up"}
            >
              <ResponsiveImage alt={"iPhone mockup of Oppyfinder app"} src={footerMockup} src2x={footerMockup2x} src3x={footerMockup3x} loading={"lazy"} />
            </AnimateOnScroll>
          </div>
        </div>
      </section>
      {/* End Download App */}
      <footer className="container mx-auto my-8 px-5">
        {/* Start Footer */}
        <div className="md:flex md:justify-between md:items-center text-xs text-center text-gray-600 space-y-3 md:space-y-0">
          <div className="space-y-2 md:space-y-0 md:flex md:justify-between md:items-center md:space-x-5">
            <p>Copyright © 2023 Oppyfinder. All rights reserved.</p>
            <p>
              <Link
                className={"text-blue-700 underline"}
                to={"/privacy-policy"}
              >
                Privacy Policy
              </Link>
              <span className={"text-gray-300 px-2"}>|</span>
              <Link
                className={"text-blue-700 underline"}
                to={"/privacy-policy"}
              >
                Terms &amp; Conditions
              </Link>
            </p>
          </div>
          <div className="flex justify-center space-x-4">
            <p>
              Hero image by{" "}
              <a
                className="text-blue-700 underline"
                href="https://unsplash.com/@seanbenesh?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                rel="nofollow noreferrer"
                target="_blank"
              >
                Sean Benesh
              </a>{" "}
              on{" "}
              <a
                className={"text-blue-700 underline"}
                href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                rel="nofollow noreferrer"
                target="_blank"
              >
                Unsplash
              </a>
            </p>
            <p>
              Site by&nbsp;
              <a
                className={"text-blue-700 underline"}
                href="https://www.mattdoesdev.com"
                target={"_blank"}
                rel="author noreferrer"
              >
                mattdoesdev
              </a>
            </p>
          </div>
        </div>
      </footer>
      {/* End Footer */}
    </main>
  );
};

export default IndexPage;
