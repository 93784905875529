import * as React from "react";

interface FeatureProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactElement;
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({
  className,
  title,
  description,
  icon,
  ...props
}) => (
  <div className={`space-y-5 ${className}`} {...props}>
    <div className="flex items-center space-x-3">
      <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-black text-white">
        {icon}
      </div>
      <h3 className="text-lg font-semibold">{title}</h3>
    </div>
    <p className="max-w-md text-base lg:text-xl text-gray-600">{description}</p>
  </div>
);

export default Feature;
