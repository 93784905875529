import * as React from "react";

interface ResponsiveImageProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  alt: string;
  src: string;
  src2x?: string;
  src3x?: string;
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  alt,
  src,
  src2x,
  src3x,
  children,
  ...props
}) => (
  <picture>
    {children}
    <img src={src} alt={alt} loading={"lazy"} {...props} />
  </picture>
);

interface ResponsiveImageSourceProps
  extends Omit<
    React.SourceHTMLAttributes<HTMLSourceElement>,
    "src" | "srcSet" | "sizes" | "media"
  > {
  minWidth?: string;
  breakpoints: {
    [key: string]: string;
  };
  imageSizes: {
    [key: string]: string;
  };
}

export const ResponsiveImageSource: React.FC<ResponsiveImageSourceProps> = ({
  minWidth,
  breakpoints,
  imageSizes,
  ...props
}) => (
  <source
    media={minWidth ? `(min-width: ${minWidth})` : undefined}
    srcSet={Object.keys(imageSizes)
      .map((size) => `${imageSizes[size]} ${size}`)
      .join(", ")}
    sizes={Object.keys(breakpoints)
      .map(
        (breakpoint) => `min-width(${breakpoint}) ${breakpoints[breakpoint]}`
      )
      .join(", ")}
    {...props}
  />
);

export default ResponsiveImage;
