import * as React from "react";
import ResponsiveImage, { ResponsiveImageSource } from "../responsive-image";
import heroMockup from "./images/mockup-iphone-store.png";
import heroMockup2x from "./images/mockup-iphone-store@2x.png";
import heroMockup3x from "./images/mockup-iphone-store@3x.png";

const HeroMockup = () => (
  <ResponsiveImage
    src={heroMockup}
    src2x={heroMockup2x}
    src3x={heroMockup3x}
    className={"inline-block"}
    alt={"Mockup of Oppyfinder app on iPhone"}
    width={300}
  >
    <ResponsiveImageSource
      breakpoints={{
        "0px": "350px",
        "600px": "200px",
        "768px": "300px",
        "1280px": "350px",
      }}
      imageSizes={{
        "300w": heroMockup,
        "600w": heroMockup2x,
        "900w": heroMockup3x,
      }}
    />
  </ResponsiveImage>
);

export default HeroMockup;
